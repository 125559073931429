<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="reporter-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <vs-table ref="table" pagination search :max-items="renderMethod.itemsPerPage" :data="items">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

            <div style="margin-right: 15px; margin-bottom: 10px">
              <vs-button type="border" color="warning" @click="xlsxDown()">엑셀 다운로드</vs-button>

              <datepicker type="date" range v-model="dateRange2" lang="kr" style="width: 200px;" />
              <!-- <date-range-picker 
                  v-model="dateRange" 
                  :locale-data="locale"
                  :opens="opens"
                  :ranges="ranges"
                >
                <div id="dates" slot="range" ></div>
              </date-range-picker> -->
            </div>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
              <div
                class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span
                  class="mr-2"
                >{{ ((currentx - 1) * renderMethod.itemsPerPage) + 1 }} - {{ numOfItems >= renderMethod.itemsPerPage ? (currentx * renderMethod.itemsPerPage) : numOfItems }} of {{ numOfItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="changeItemPerPage(4)">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemPerPage(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemPerPage(15)">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemPerPage(30)">
                  <span>30</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemPerPage(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemPerPage(100)">
                  <span>100</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <template slot="thead">
            <vs-th sort-key="name">이름</vs-th>
            <vs-th sort-key="newsCnt">뉴스갯수</vs-th>
            <vs-th sort-key="totalViews">합산조회수</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td><div style="font-weight: bold" @click="selectReporter(tr.reporterIdx)">{{ tr.name }}</div></vs-td>
                <vs-td><div>{{ tr.newsCnt }}</div></vs-td>
                <vs-td><div style="color: blue">{{ tr.totalViews }}</div></vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      <div class="vx-col w-1/2">
        <vs-table v-if="selectedReporter" ref="table" pagination search :max-items="renderMethod.itemsPerPage" :data="selectedData">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          </div>

          <template slot="thead">
            <vs-th sort-key="title">제목</vs-th>
            <vs-th sort-key="publishedTime">날짜</vs-th>
            <vs-th sort-key="viewed">조회수</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td><div style="font-weight: bold">{{ tr.title }}</div></vs-td>
                <vs-td><div>{{ tr.publishedTime }}</div></vs-td>
                <vs-td><div style="color: blue">{{ tr.viewed }}</div></vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
    <!-- <div>
      <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div> -->

  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';
import moment from 'moment';
import * as xlsx from 'xlsx';


export default {
  components: {
    // AddNewDataSidebar
    DateRangePicker,
  },
  data() {
    return {
      items: [],
      currentx: 1,
      numOfItems: 0,
      numOfPages: 0,
      dateRange: { // used for v-model prop
          startDate: moment(),
          endDate: moment(),
      },
      dateRange2: [new Date(), new Date()],
      renderMethod: {
        itemsPerPage: 20,
      },
      isMounted: false,
      selectedReporter: null,
      selectedData: [],
    };
  },
  watch: {
    
    dateRange2: function() {
      
      this.searchStatisticsDesc()
    }
  },
  // computed: {
  //   currentPage() {
  //     if (this.isMounted) {
  //       return this.$refs.table.currentx;
  //     }
  //     return 0;
  //   }
  // },
  methods: {
    detect() {

      console.log('detect')
    },
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum;
      this.getUserList();
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    xlsxDown(){
      const workBook = xlsx.utils.book_new()
      const workSheet = xlsx.utils.json_to_sheet(this.items)
      xlsx.utils.book_append_sheet(workBook, workSheet, 'result')
      xlsx.writeFile(workBook, `Reporter_${moment(this.dateRange2[0]).format('YYYY-MM-DD')}_${moment(this.dateRange2[1]).format('YYYY-MM-DD')}.xlsx`)
    },

    selectReporter(reporterIdx){
      const thisIns = this;
      this.selectedReporter = reporterIdx;
      this.$vs.loading({
        scale: 1.5
      });

      let sendData = {
        startDate: moment(this.dateRange2[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange2[1]).add(1, 'day').format('YYYY-MM-DD'),
        idx: reporterIdx,
      }
      this.$http
      .post("/api/statistics/myOwn", sendData)
      .then(function(response) {

        thisIns.$vs.loading.close()
        console.log(response)

        thisIns.selectedData = response.data
      })
      .catch(function(error) {

        thisIns.$vs.loading.close()
        
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
      });
    },
    searchStatisticsDesc() {

      var thisIns = this

      var fixedDateRange = {
        startDate: moment(this.dateRange2[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange2[1]).add(1, 'day').format('YYYY-MM-DD')
        // startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        // endDate: moment(this.dateRange.endDate).add(1, 'day').format('YYYY-MM-DD')
      }

      this.$vs.loading({
        scale: 1.5
      });

      this.$http
      .post("/api/statistics/eachReporter", fixedDateRange)
      .then(function(response) {

        thisIns.$vs.loading.close()
        console.log(response)
        thisIns.items = response.data
        thisIns.numOfItems = thisIns.items.length
        thisIns.currentx = 1;
      })
      .catch(function(error) {

        thisIns.$vs.loading.close()

        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
      });
    },
  },
  created() {

      var thisIns = this

      var fixedDateRange = {
        startDate: moment(this.dateRange2[0]).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange2[1]).add(1, 'day').format('YYYY-MM-DD')
        // startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        // endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }

      this.$vs.loading({
        scale: 1.5
      });

      this.$http
      .post("/api/statistics/eachReporter", fixedDateRange)
      .then(function(response) {

        thisIns.$vs.loading.close()
        console.log(response)

        thisIns.items = response.data
        thisIns.numOfItems = thisIns.items.length
        thisIns.currentx = 1;
      })
      .catch(function(error) {

        thisIns.$vs.loading.close()
        
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
      });
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-reporter.scss";
</style>